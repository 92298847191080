<template>
  <div class="morado gradientBK">
    <Header />

    <div class="B1  gradientBK">
      <div class="B1F2">
        <div class="B1F2_B1">
          <p class="B1T1">That we lead</p>
          <p class="B1T2">
            Hace una década atrás, tuvimos la inquietud por enseñarle al mundo nuestro alto potencial en el diseño y marketing digital. Desde entonces hemos tenido la oportunidad de liderar proyectos con marcas y empresas locales y nacionales. 
          </p>
        </div>
        <div class="B1F2_B2">
          <img src="../assets/Frame.svg" />
        </div>
      </div>
    </div>

    <div class="portfolio-menu">
      <div class="menu-cont">
        <p :class="{ active_li: liOpc == 'ALL' , bor_cat1 : liOpc == 'ALL'  }" @click="getOption('ALL')">
          All
        </p>
        <p
          :class="{ active_li: liOpc == 'BRANDING' , bor_cat6 : liOpc == 'BRANDING'  }"
          @click="getOption('BRANDING')"
        >
          Branding
        </p>
        <p
          :class="{ active_li: liOpc == 'MARKETING' , bor_cat5 : liOpc == 'MARKETING'  }"
          @click="getOption('MARKETING')"
        >
          Digital Marketing
        </p>
        <p
          :class="{ active_li: liOpc == 'DEVELOPMENT' , bor_cat2 : liOpc == 'DEVELOPMENT' }"
          @click="getOption('DEVELOPMENT')"
        >
          Development
        </p>
        <p
          :class="{ active_li: liOpc == 'DESIGN' , bor_cat3 : liOpc == 'DESIGN'  }"
          @click="getOption('DESIGN')"
        >
          Design
        </p>
      </div>
    </div>

    <div class="modal-image"  v-show="boolModal" @click="closeFrame()">
      <img @click.stop="" v-if="this.dataModal != null" :src="urlpath.url() + '/project-img/'+this.dataModal.image" alt="image">
    </div>

    <div class="portfolio-cont">
      <div class="por-sec-cont">
        <div class="item-cont" v-for="(port, i) in portfolio" :key="i"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="1500"
            :data-aos-delay="1000 + i * 10">
          <div>
            <div v-if="port.category_name == liOpc || liOpc == 'ALL'">
                <div v-if="port.statusproject!='OCULTO'">
                  <div v-if="port.formato=='LARGO'">
                    <a :href="'/portafolio-post/' + port.url" class="cont-center">
                      <div class="cont-pt-hover port-item">
                        <p class="category-pt">{{port.category_name | split}}</p>
                        <p class="titulo-pt">{{port.title}}</p>
                      </div>
                      <img v-if="port.imagemin==null || port.imagemin =='' "
                        class="port-item"
                        :src="urlpath.url() + '/project-img/'+port.image"
                        :alt="port.title"
                      />
                      <img v-else
                        class="port-item"
                        :src="urlpath.url() + '/project-img/'+port.imagemin"
                        :alt="port.title"
                      />
                    </a>    
                  </div>
                  <div v-else-if="port.formato=='CORTO'" @click="imageFrame(port)" class="cont-center">
                      <div class="cont-pt-hover port-item">
                        <p class="category-pt">{{port.category_name | split}}</p>
                        <p class="titulo-pt">{{port.title}}</p>
                      </div>
                      <img
                        class="port-item"
                        style="cursor:pointer"
                        :src="urlpath.url() + '/project-img/'+port.image"
                        :alt="port.title"
                      />
                  </div>
                </div>            
            </div>            
          </div>


        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Header from "../components/on-site/Header";
import Footer from "../components/on-site/Footer.vue";
let urlpath = require("../global/url");

export default {
  name: "Services",
  data() {
    return {
      urlpath: urlpath,
      liOpc: "ALL",
      boolModal: false,
      dataModal: null,
      portafolio: [
        {
          titulo: "tienda",
          image: require("../assets/port-1.png"),
          link: "/",
          categories: "Branding",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-2.png"),
          link: "/",
          categories: "Digital Marketing",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-3.png"),
          link: "/",
          categories: "Development",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-4.png"),
          link: "/",
          categories: "Design",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-5.png"),
          link: "/",
          categories: "Branding",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-6.png"),
          link: "/",
          categories: "Digital Marketing",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-1.png"),
          link: "/",
          categories: "Branding",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-2.png"),
          link: "/",
          categories: "Digital Marketing",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-3.png"),
          link: "/",
          categories: "Development",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-4.png"),
          link: "/",
          categories: "Design",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-5.png"),
          link: "/",
          categories: "Branding",
        },
        {
          titulo: "tienda",
          image: require("../assets/port-6.png"),
          link: "/",
          categories: "Digital Marketing",
        },
      ],
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.getAllInfoPrj("project");
    this.routesUpd();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    portfolio() {
      return this.$store.getters["project/data"];
    },
  },
  methods: {
    ...mapActions("project", ["getAllInfoPrj"]),

    imageFrame(data){
      this.boolModal = true;
      this.dataModal = data;
    },

    closeFrame(){
      this.boolModal = false;
      this.dataModal = null;
    },


    changeBackgroundPartners(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("gradientBK")[0];
      let divcont1 = document.getElementsByClassName("gradientBK")[1];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      } else if (min768.matches) {
        /**#e4e4f9 */
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      }
    },
    getAllInfoPrj: async function () {
      let result = await this.$store.dispatch("project/getAllInfoPrj", "project");
      //console.log(result)
      this.info = result;
      return result;
    },
    srcImg: function (img) {
      let src = `${urlpath.url()}/project-img/${img}`;
      return src;
    },
    getOption: function (option) {
      if(option == "ALL"){
        this.$router.push('/portafolio');
      }else{
        this.$router.push('/portafolio/'+option.toLowerCase());
      }
      this.liOpc = option;
    },
    
    splitHTML(texto) {
      let regex = /(<([^>]+)>)/gi;
      let textoS = texto.replace(regex, "");
      let maxLength = 40;
      let trimmedString = textoS.substr(0, maxLength);
      trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      );
      return trimmedString + " ...";
    },
    routesUpd(){
      if(this.$route.path == '/portafolio'){
        this.liOpc = 'ALL'
      }else if(this.$route.path == '/portafolio/branding'){
        this.liOpc = 'BRANDING'
      }else if(this.$route.path == '/portafolio/marketing'){
        this.liOpc = 'MARKETING'
      }else if(this.$route.path == '/portafolio/developing'){
        this.liOpc = 'DEVELOPING'
      }else if(this.$route.path == '/portafolio/design'){
        this.liOpc = 'DESIGN'
      }
    }
  },
  mounted(){
    window.addEventListener("scroll", this.changeBackgroundPartners);
    window.addEventListener("load", this.changeBackgroundPartners);
  },
     filters:{
     split: (value) => {
            if (!value) return '';
            value = value.toString();

            let splitStr = value.toLowerCase().split(' ');
            let len = splitStr.length
            let i;
               
            if(len == 1){
                 return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);  
            }

            for ( i = 0; i < len; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            let nameCap = splitStr.join(' '); 
            let newName = nameCap.split(' ').slice(0, -1).join(' ')//cantidad de palabras quitadas.

            return newName;

        },
 },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Portfolio - Legrafica';
            }
        },
  }
};
</script>

<style scoped>
.morado
{
  width: 100vw;
  background-color: #e4e4f9;
  overflow: hidden;
  }
.modal-image {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    background: #0000005c;
}
.modal-image img{
    width: auto;
    height: 46vw;
}
.B1 {
  width: 100%;
  height: 71.71875vw;
  /*background: linear-gradient(#e4e4f9 80%, #ffffff);*/
}
.cont-pt-hover{
  position: absolute;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  transition: .5s;
}
.item-cont:hover .cont-pt-hover{
  opacity: 1;
  pointer-events: all;
}
.Header {
  display: flex;
  width: 92.5vw;
  height: 10.572916666666666vw;
  padding: 0vw 3.28125vw;
  margin: 0vw;
  justify-content: space-between;
}

.B1F2_B1 {
  margin-left: 10.677083333333332vw;
  z-index: 1;
  width: 10%;
}
.B1F2 {
  display: flex;
}
.B1T1 {
  width: 43.5vw;
  color: #ef67c2;
  font-size: 7.8125vw;
  line-height: 9.479166666666666vw;
  font-weight: 500;
  font-family: "Gramatika-Medium";
  margin-top: 16vw;
}
.B1T2 {
  width: 27vw;
  font-size: 0.8333333333333334vw;
  line-height: 1.4984374999999999vw;
  font-family: "IBM Plex Mono";
  margin-top: -3vw;
}
.B1F2_B2 {
  width: 100%;
}
.B1F2_B2 img {
  width: 75.46875vw;
  margin-top: 0vw;
  float: right;
  height: 59.21875vw;
}

.B2F1 {
  display: flex;
  padding-left: 10.15625vw;
}
.B2F1_I1 {
  width: 5vw;
  height: 5vw;
  margin-top: 18vw;
  margin-right: 2.864583333333333vw;
}
p.B2Title {
  color: #987ef3;
  font-size: 2.083333333333333vw;
  margin-bottom: 3.28125vw;
}
p.B2Text {
  width: 18.5vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.1213541666666667vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 3.58125vw;
}
ul.B2List {
  padding-left: 0vw;
}
ul.B2List {
  padding-left: 0vw;
}
.B2List li {
  width: 18.5vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.7994791666666665vw;
  font-family: "IBM Plex Mono";
  list-style: none;
}

.B2PH1 {
  margin-right: 2.864583333333333vw;
  margin-left: 12.552083333333334vw;
}
img.B2C1 {
  width: 15.052083333333332vw;
  margin-right: -14vw;
}
img.B2I1 {
  width: 12.760416666666666vw;
  margin-bottom: -4vw;
}
img.B2C2 {
  width: 15.052083333333332vw;
  margin-right: -14vw;
}
img.B2I2 {
  width: 12.760416666666666vw;
  margin-bottom: -4vw;
}

.B2F2 {
  margin-top: 6vw;
  margin-bottom: 15vw;
  padding-left: 15.885416666666666vw;
}
img.B2F2_I1 {
  width: 15.885416666666666vw;
}
img.B2F2_I2 {
  width: 13.28125vw;
  margin-left: 7vw;
  margin-bottom: 9vw;
}
img.B2F2_I3 {
  width: 21.302083333333332vw;
  margin-left: 13.28125vw;
  margin-bottom: -4vw;
}

.B3F1 {
  display: flex;
}
img.B3F1_I1 {
  width: 43.4375vw;
  height: 30vw;
  margin-left: 10.260416666666666vw;
  margin-right: 7.447916666666667vw;
}
img.B3F1_I2 {
  width: 5vw;
  height: 5vw;
  margin-top: 18vw;
  margin-right: 2.864583333333333vw;
}
p.B3Title {
  color: #987ef3;
  font-size: 2.083333333333333vw;
  margin-bottom: 3.28125vw;
}
p.B3Text {
  width: 21vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.1213541666666667vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 4.28125vw;
}
ul.B3List {
  padding-left: 0vw;
}
.B3List li {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.7994791666666665vw;
  font-family: "IBM Plex Mono";
  list-style: none;
}

.B3F2 {
  padding-top: 9.365104166666667vw;
  padding-left: 7vw;
  margin-bottom: 15.625vw;
}
img.B3F2_I1 {
  width: 28.385416666666668vw;
  margin-right: 6vw;
  margin-bottom: 18vw;
}
img.B3F2_I2 {
  width: 12.291666666666666vw;
  margin-right: 12vw;
}
img.B3F2_I3 {
  width: 21.302083333333332vw;
  margin-bottom: 19vw;
}

.B4 {
  padding-left: 10.416666666666668vw;
}
.B4F1 {
  display: flex;
}

img.B4I1 {
  width: 5vw;
  height: 5vw;
  margin-top: 18vw;
  margin-right: 2.864583333333333vw;
}
p.B4Title {
  color: #987ef3;
  font-size: 2.083333333333333vw;
  margin-bottom: 3.28125vw;
}
p.B4Text {
  width: 21vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.1213541666666667vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 4.28125vw;
}
ul.B4List {
  padding-left: 0vw;
}
.B4List li {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.7994791666666665vw;
  font-family: "IBM Plex Mono";
  list-style: none;
}
img.B4I2 {
  width: 41.5625vw;
  margin-left: 9.010416666666666vw;
  margin-top: -6vw;
}

img.B4F2_I1 {
  width: 21.302083333333332vw;
  padding-bottom: 10vw;
  margin-right: 6.302083333333333vw;
  margin-left: 1vw;
}
img.B4F2_I2 {
  width: 21.302083333333332vw;
  padding-bottom: 5vw;
  margin-right: 6.302083333333333vw;
}
img.B4F2_I3 {
  width: 21.302083333333332vw;
}

.B5 p {
  width: 61vw;
  font-size: 6.25vw;
  line-height: 7.581250000000001vw;
  color: #ef67c2;
  margin: 31.71875vw 0vw;
  margin-left: 21.666666666666668vw;
}
.B5 span {
  color: #987ef3;
}

.portfolio-menu {
  width: 100%;
  padding-bottom: 5.521vw;
}
.portfolio-menu .menu-cont {
  display: flex;
  margin: 0 auto;
  width: 60%;
  justify-content: space-between;
}
.portfolio-menu .menu-cont p {
  font-family: Gramatika-Bold;
  font-size: 0.938vw;
  line-height: 125%;
  color: #000000;
  cursor: pointer;
}
.portfolio-menu {
  position: absolute;
  top: 61.615vw;
}
.active_li {
  color: #987ef3;
  border-bottom: 0.3vw solid #987ef3;
  padding-bottom: 2.1vw;
}
.por-sec-cont {
  margin: auto;
  width: 79vw;
  height: fit-content;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  padding-top: 6.51vw;
  padding-bottom: 24.896vw;
  align-items: center;
}
.cont-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
/*.port-item {
  height: auto;
  width: 100%;
    object-fit: cover;
}*/
.item-cont {
  height: fit-content;
  transition: .5s;
}
.item-cont:nth-child(odd) {
  height: auto;
    width: fit-content;
}
.item-cont:nth-child(even) {
  height: auto;
    width: fit-content;
}
.item-cont:nth-child(odd) .port-item{
  width: 19.688vw;
  margin: 2vw 1vw;
  transition: .5s;
    object-fit: cover;
    pointer-events: all;
}
.item-cont:nth-child(even) .port-item{
  width: 20.208vw;
  transition: .5s;
  margin: 2vw 4vw;
    object-fit: cover;
}
.item-cont:hover img.port-item{
  filter: brightness(.6);
}
.item-cont:nth-child(odd) .port-item2{
  width: 19.688vw;
}
.item-cont:nth-child(even) .port-item2{
  width: 20.208vw;
}
.category-pt{
  font-family: 'Gramatika-Bold';
font-size: 0.677vw;
line-height: 125%;
color: #FFFFFF;
  padding-left: 1.708vw;
  margin: 0;
}
p.titulo-pt {
    font-family: 'Gramatika-Bold';
    font-size: 1.302vw;
    line-height: 136.5%;
    /*text-decoration-line: underline;*/
    color: #FFFFFF;
    padding-bottom: 1.563vw;
  padding-left: 1.708vw;
  margin: 0;
}
.btn-pt {
    width: 5.469vw;
    height: 2.083vw;
    margin-left: 2.708vw;
    background: #3702FB;
    color: white;
    display: flex;
    font-family: 'Gramatika-Bold';
    font-size: 0.677vw;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {

  
  .B1F2_B2 {
    display: none;

  }
  .B1F2_B1 {
    margin-left: 12.802vw;
    width: 100%;
  }
  .B1T1 {
    width: 68.5vw;
    color: #ef67c2;
    font-size: 14.493vw;
    line-height: 14.493vw;
    font-weight: 500;
    font-family: "Gramatika-Medium";
    margin-top: 41.787vw;
  }
  .B1T2 {
    width: 76.329vw;
    font-size: var(--size11mov);
    line-height: 5.268115942028985VW;
    margin-top: -3vw;
  }
  .B1 {
    width: 100%;
    height: 158.454vw;
    background-image: url(../assets/pleca_projects.png);
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .portfolio-menu {
    position: absolute;
    top: 130.396vw;
    overflow-x: auto;
    overflow-y: hidden;
    width: 96%;
  }
  .portfolio-menu .menu-cont {
    width: fit-content;
  }
  .portfolio-menu .menu-cont p {
    font-family: Gramatika-Bold;
    font-size: 3.14vw;
    line-height: 125%;
    color: #000000;
    margin: 0 7vw;
    width: fit-content;
    text-align: center;
  }
  .portfolio-menu .menu-cont p:nth-child(3) {
    width: 27vw;
  }
  .active_li {
    color: #987ef3;
    border-bottom: 0.6vw solid #987ef3;
    padding-bottom: 4.1vw;
  }
  /* .item-cont:nth-child(odd) {
    width: 34.783vw;
    margin: 1vw 1vw;
  }
  .item-cont:nth-child(even) {
    width: 38.164vw;
    margin: 1vw 5vw;
  }*/
  .por-sec-cont {
    margin: auto;
    width: 95vw;
   /* height: 340vw;*/
    height: fit-content;
    justify-content: center;
  }
.item-cont{
  margin:0 .5vw
}

  .item-cont:nth-child(3n+1){
    width: 84.300vw;
  }
  .item-cont:nth-child(3n+1) .port-item{
    width: 100%;
  }
/***41.304vw */

  .item-cont:nth-child(3n+2){
    width: 44.686vw;
  }
  .item-cont:nth-child(3n+2) .port-item{
    width: 100%;
  }

  .item-cont:nth-child(3n+3){
    width: 41.304vw;
  }
  .item-cont:nth-child(3n+3) .port-item{
    width: 100%;
  }

  .category-pt {
    font-size: 4.677vw;
    line-height: 133%;
    padding-left: 4.708vw;
  }
  p.titulo-pt { 
    font-size: 6.302vw;
    line-height: 99.5%; 
    padding-bottom: 3.563vw;
    padding-left: 4.708vw;
}
.modal-image img {
    width: 85vw;
    height: auto;
}
}
</style>